import invariant from 'invariant';
import { Map, fromJS } from 'immutable';
import { LOAD_COUNTRIES_ERROR, LOAD_COUNTRIES_SUCCESS } from './index.js';
export { REDUX_AND_SAGA_KEY } from './index.js';
import '@dbh/reselect-extra';
import 'reselect';
import '@dbh/routing-redux';
import '@dbh/lodash-extra';
import 'react-immutable-proptypes';
import '@dbh/with-conforms-to-for-production-www';
import 'prop-types';

const initialState=Map({countries:Map(),countriesLoaded:Map(),error:void 0,isError:!1}),countriesReducer=function(a,b){void 0===a&&(a=initialState);const{type:c}=b;switch(c){case LOAD_COUNTRIES_SUCCESS:{const{payload:{locale:c,country:d,countries:e}}=b,f=[d,c];return invariant(c&&d,"The LOAD_COUNTRIES_SUCCESS ("+LOAD_COUNTRIES_SUCCESS+") event must include valid `country` and `locale` in its payload."),a.withMutations(a=>a.setIn(["countries",...f],fromJS(e)).setIn(["countriesLoaded",...f],!0))}case LOAD_COUNTRIES_ERROR:{const{payload:{error:c}}=b;return a.merge({error:c.message,isError:!0})}default:return a}};

export { countriesReducer as default };
